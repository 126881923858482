import { ReactElement, SVGProps } from "react";

export enum OauthProvider {
  Hubspot = "hubspot",
  Salesforce = "salesforce",
  Dynamics365 = "dynamics365",
  Mailchimp = "mailchimp",
}

export enum OauthStatus {
  Connected = "ok",
  NotConnected = "not_connected",
}

export enum OauthImportStatus {
  NotStarted = "not_started",
  Pending = "pending",
  Processed = "processed",
  Failed = "failed",
}

export interface ImportProvider {
  id: OauthProvider;
  name: string;
  description?: string;
  email?: string;
  logo: (props: SVGProps<SVGElement>) => ReactElement;
  connected: boolean;
  disabled?: boolean;
}

interface ImportProviderStatus {
  status: OauthStatus;
  email: string;
  isDisconnectable: boolean;
}

export interface GetProvidersStatus {
  hubspot: ImportProviderStatus;
  salesforce: ImportProviderStatus;
  dynamics365: ImportProviderStatus;
  mailchimp: ImportProviderStatus;
}

export interface GetOauthUrlRequest {
  provider: OauthProvider;
  organisationId: number;
  channelId?: number;
}

export interface GetOauthUrlResponse {
  url: string;
}

export interface OauthImportItem {
  id: string;
  channelId: number;
  emailFileId?: string;
  parsedEmailCount: number | null;
  status: OauthImportStatus;
  type: OauthProvider;
}

export interface GetOauthImportsForChannelResponse {
  data: OauthImportItem[];
}

export type ImportSegmentValue = string | number;

export type ImportSegmentState = {
  listId: ImportSegmentValue;
  segmentId?: ImportSegmentValue;
};

export interface ImportSegment {
  label: string;
  value: ImportSegmentValue;
  size?: number;
}

/* hubspot */

interface HubspotListItem {
  listId: number;
  name: string;
  size: number;
}

export interface GetHubspotListsRequest {
  organisationId: number;
}

export interface GetHubspotListsResponse {
  lists: HubspotListItem[];
}

export interface HubspotImportRequest {
  channelId: number;
  listId: number;
}

/* salesforce */

interface SalesforceListItem {
  listViewId: string;
  label: string;
}

export interface GetSalesforceListsRequest {
  organisationId: number;
}

export interface GetSalesforceListsResponse {
  listViews: SalesforceListItem[];
}

export interface SalesforceImportRequest {
  channelId: number;
  listViewId: string;
}

export enum SalesforceImportErrors {
  NotEnabled = "not enabled for this salesforce organization",
}

export enum OauthImportErrors {
  ListNotFound = "list not found",
  ListEmpty = "list empty",
  SegmentNotFound = "segment not found",
  SegmentEmpty = "segment empty",
}

/* mailchimp */

interface MailchimpListItem {
  id: string;
  name: string;
  memberCount: number;
}

interface MailchimpSegmentItem extends Omit<MailchimpListItem, "id"> {
  id: number;
}

export interface GetMailchimpListsRequest {
  organisationId: number;
}

export interface GetMailchimpListsResponse {
  lists: MailchimpListItem[];
}

export interface GetMailchimpSegmentsRequest {
  organisationId: number;
  listId: string;
}

export interface GetMailchimpSegmentsResponse {
  segments: MailchimpSegmentItem[];
}

export interface GetMailchimpListsSegmentsResponse {
  data: {
    list: MailchimpListItem;
    segments: MailchimpSegmentItem[];
  }[];
}

export interface MailchimpImportRequest {
  channelId: number;
  listId: string;
  segmentId?: number;
}
